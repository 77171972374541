<template>
  <div class="rewards-frame-container" v-if="rewardType == 'luckyspin'">
    <iframe id="rewardsFrame" class="rewardsFrame" :src="this.rewardsUrl" />
  </div>
  <!-- <v-row v-else-if="rewardsType=='angpao'" no-gutters>-->
  <!--    <v-col cols="12" class="padding-angpao-frame">-->
  <!--      <v-row>-->
  <!--        <v-col :cols="12" :lg="8" class="mx-auto text-center text-h4 navigator_active&#45;&#45;text font-weight-bold">-->
  <!--          {{$t(`label.AngpaoPrize`)}}-->
  <!--        </v-col>-->
  <!--        <v-col :cols="12" :lg="8" class="mx-auto text-center text-h5 navigator_active&#45;&#45;text">-->
  <!--          Chances : {{ angpao_chance }}-->
  <!--        </v-col>-->
  <!--        <v-col cols="10" class="mx-auto">-->
  <!--          <v-img @click="unlockAngpao()" style="cursor:pointer;"-->
  <!--                 :src="!isClaimed ? `${mediaUrl}/cny/raya_angpao_static.png` : `${mediaUrl}/cny/raya_angpao_open.gif`"-->
  <!--                 :aspect-ratio="1307/857"-->
  <!--                 width="700"-->
  <!--                 height="auto" class="mx-auto">-->
  <!--          </v-img>-->
  <!--        </v-col>-->
  <!--        <v-col cols="10" class="mx-auto text-center">-->
  <!--          <p>{{ $t(`label.termsAndCondition`) }}</p>-->
  <!--          <p>{{ $t(`label.requirements`) }}</p>-->
  <!--          <p>{{ $t(`label.generalAngpao`) }}</p>-->
  <!--        </v-col>-->
  <!--      </v-row>-->
  <!--    </v-col>-->
  <!--  </v-row>-->
  <v-row v-else-if="rewardType == 'dailycheckin'" class="px-10 mt-10">
    <v-col cols="12" class="daily_check_in">
      <v-img width="2391" class="header_daily" src="/static/image/promotion/daily_check_in.jpg" :aspect-ratio="2391 / 789">
        <!--                <p class="header_daily font-weight-bold display-2 pt-8">Daily Mission</p>-->
      </v-img>
    </v-col>
    <!--        <v-col cols="12" style="position:relative; z-index:1">-->
    <!--            <v-img width="800" height="180" class="header_daily" src="/static/image/promotion/daily_rewards_header.png"-->
    <!--                   :aspect-ratio="1725/434">-->
    <!--                <p class="header_daily font-weight-bold display-2 pt-8">Daily Mission</p>-->
    <!--            </v-img>-->
    <!--        </v-col>-->
    <!--        <v-col cols="12" class="white black&#45;&#45;text" style="border-radius: 6px;position:relative;">-->
    <!--            <v-row style="margin-top:80px" class="px-8">-->
    <!--                <v-col cols="8">-->
    <!--                    <v-row justify="center" align="center">-->
    <!--                        <v-col cols="3">-->
    <!--                            <span class="black&#45;&#45;text font-weight-bold title">Progress Rewards:</span>-->
    <!--                        </v-col>-->
    <!--                        <v-col cols="9">-->
    <!--                            <v-progress-linear-->
    <!--                                    rounded-->
    <!--                                    v-model="scale_progress"-->
    <!--                                    color="deep-purple accent-4"-->
    <!--                                    height="25"-->
    <!--                            >-->
    <!--                                <template v-slot="{ value }">-->
    <!--                                    <strong>{{ Math.ceil(value) }}%</strong>-->
    <!--                                </template>-->
    <!--                            </v-progress-linear>-->
    <!--                        </v-col>-->
    <!--                    </v-row>-->
    <!--                    <v-row justify="space-between">-->
    <!--                        <v-col cols="auto" class="black&#45;&#45;text font-weight-bold title">-->
    <!--                            Total Deposit : {{member_deposit | currency}}-->
    <!--                        </v-col>-->
    <!--                        <v-col cols="auto" class="black&#45;&#45;text font-weight-bold title">-->
    <!--                            Required Deposit : {{required_deposit | currency}}-->
    <!--                        </v-col>-->
    <!--                    </v-row>-->
    <!--                    <v-row>-->
    <!--                        <v-col cols="12" style="background-color:#150B2E; border-radius:5px;" class="padding-huge">-->
    <!--                            <v-row justify="center" align="center">-->
    <!--                                <v-col :cols="k== 6 ? 12 : 4" v-for="(n,k) in weeks"-->
    <!--                                       :class="['px-5', {'is_future' : n.future}]">-->
    <!--                                    <v-hover-->
    <!--                                            v-slot:default="{ hover }"-->
    <!--                                            open-delay="200"-->
    <!--                                    >-->
    <!--                                        <v-card :class="['border-curve' , {'not_future': !n.future, 'on-hover':hover}]"-->
    <!--                                                color="white" :elevation="hover?16:2">-->
    <!--                                            <v-card-title-->
    <!--                                                    :class="['pa-2 font-weight-bold d-flex justify-center', hover && !n.future ? 'active-header': 'not-active-header']">-->
    <!--                                                {{n.dayName}}-->
    <!--                                            </v-card-title>-->
    <!--                                            <v-card-text class="pa-3 pb-0 display-2 font-weight-bold text-center">-->
    <!--                                                {{n.day}}/{{n.month}}-->
    <!--                                            </v-card-text>-->
    <!--                                            <v-card-text class="pa-0 title font-weight-bold text-center">-->
    <!--                                                {{n.year}}-->
    <!--                                            </v-card-text>-->
    <!--                                            <v-card-text class="pa-2 title font-weight-bold text-center">-->
    <!--                                                Not Eligible-->
    <!--                                            </v-card-text>-->
    <!--                                        </v-card>-->
    <!--                                    </v-hover>-->
    <!--                                </v-col>-->
    <!--                            </v-row>-->
    <!--                        </v-col>-->
    <!--                    </v-row>-->
    <!--                </v-col>-->
    <!--                <v-col cols="4" class="mt-4">-->
    <!--                    <v-card color="#f4f4f4" class="white&#45;&#45;text">-->
    <!--                        <v-list>-->
    <!--                            <v-list-item>-->
    <!--                                <v-list-item-content class="font-weight-bold">-->
    <!--                                    Prizes:-->
    <!--                                </v-list-item-content>-->
    <!--                            </v-list-item>-->
    <!--                            <v-list-item v-for="(n,k) in prizeList">-->
    <!--                                <v-list-item-content>-->
    <!--                                    <v-row>-->
    <!--                                        <v-col cols="12">-->
    <!--                                            Week: {{k+1}}-->
    <!--                                        </v-col>-->
    <!--                                    </v-row>-->
    <!--                                    <v-row>-->
    <!--                                        <v-col cols="auto">-->
    <!--                                            {{n.prize}}-->
    <!--                                        </v-col>-->
    <!--                                       <v-spacer></v-spacer>-->
    <!--                                        <v-col cols="auto">-->
    <!--                                            Quantity: {{n.quantity}}-->
    <!--                                        </v-col>-->
    <!--                                    </v-row>-->
    <!--                                </v-list-item-content>-->
    <!--                            </v-list-item>-->
    <!--                        </v-list>-->
    <!--                        &lt;!&ndash;                        <v-date-picker v-model="date" @input="showTimePicker" full-width color="primary" :max="maxDate"&ndash;&gt;-->
    <!--                        &lt;!&ndash;                                       :locale="language"></v-date-picker>&ndash;&gt;-->
    <!--                    </v-card>-->

    <!--                    <v-card color="#f4f4f4" class="white&#45;&#45;text mt-10">-->
    <!--                        <v-list>-->
    <!--                            <v-list-item>-->
    <!--                                <v-list-item-content>-->
    <!--                                    Terms and condition:-->
    <!--                                </v-list-item-content>-->
    <!--                            </v-list-item>-->
    <!--                            <v-list-item v-for="(n,k) in termsCondition">-->
    <!--                                <v-list-item-content>-->
    <!--                                    {{k+1}}. {{n}}-->
    <!--                                </v-list-item-content>-->
    <!--                            </v-list-item>-->
    <!--                        </v-list>-->
    <!--                        &lt;!&ndash;                        <v-date-picker v-model="date" @input="showTimePicker" full-width color="primary" :max="maxDate"&ndash;&gt;-->
    <!--                        &lt;!&ndash;                                       :locale="language"></v-date-picker>&ndash;&gt;-->
    <!--                    </v-card>-->
    <!--                </v-col>-->
    <!--            </v-row>-->
    <!--        </v-col>-->
  </v-row>
  <v-row v-else class="px-10 mt-10">
    <v-col cols="12" class="daily_check_in">
      <iframe class="rewardsFrame" :src="this.rewardsUrl" />
    </v-col>
  </v-row>
</template>

<script>
import { LUCKY_SPIN } from '@/constants/constants'
import format from 'string-format'
import { SESSION } from '@/constants/constants'
import { uiHelper } from '@/util/ui-helper'
import { SHARED } from '../../constants/constants'
import { locale } from '@/util/localization-helper'
import { RESET_REWARDS_URL, REWARDS_URL } from '../../store/member.module'
import { ROUTE_NAME } from '../../constants/route.constants'
import { ANGPAO_CHANCE_GET, ANGPAO_CLAIM, RESET_ANGPAO_CLAIM } from '@/store/cms.module'
import { errorCodeHelper } from '@/util'

export default {
  name: 'rewards',
  props: {
    rewardType: {
      type: String,
      default: 'luckyspin',
      required: true
    }
  },
  data: () => ({
    isClaimed: false,
    mediaUrl: SHARED.MEDIA_SERVER_URL,
    prizeList: [
      {
        prize: 'RM 5 Free Bet',
        quantity: '100 units'
      },
      {
        prize: 'RM 10 Free Bet',
        quantity: '55 units'
      },
      {
        prize: '5x PNG Free Spins',
        quantity: '1000 units'
      },
      {
        prize: 'RM 500 Free Bet',
        quantity: '5 units'
      }
    ],
    termsCondition: [
      "Every weeks there's a new prizes for members to unlock if they complete check in mission.",
      'Members have to make eligible success deposit to BABU88 in order to participate in this check in mission.',
      'Members only able to check in particular day if they made deposit a day before the check in date.',
      'Prizes given will be limited, and BABU88 reserves the right to cancel the prizes given to member if found fraud cases.',
      'Minimum deposit amount to be qualified for check-in is RM 100'
    ],
    thisWeek: [],
    nameOfDay: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    member_deposit: 25,
    required_deposit: 100,
    scale_progress: 25,
    spinUrl: SHARED.SPIN_WHEEL_URL,
    rewardsUrl: '',
    currentLanguage: uiHelper.getLanguage()
  }),
  computed: {
    angpao_claim() {
      return this.$store.state.cms.angpaoClaim.complete
    },
    angpao_chance() {
      return this.$store.state.cms.angpaoChance.chances
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    ageUrl() {
      return this.$store.state.member.rewards.complete
    },
    rewardsType() {
      return this.$route.params.type
    },
    weeks() {
      let curr = new Date()
      let thisWeek = []

      for (let i = 1; i <= 7; i++) {
        let first = curr.getDate() - curr.getDay() + i
        let day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        thisWeek.push({
          date: day,
          day: new Date(day).getDate(),
          month: new Date(day).getMonth() + 1,
          year: new Date(day).getFullYear(),
          dayName: locale.getMessage(`label.${this.nameOfDay[new Date(day).getDay()]}`),
          future: new Date(day).getDate() > new Date().getDate() || new Date(day).getDate() < new Date().getDate()
        })
      }
      console.log(thisWeek)
      return thisWeek
    }
  },
  created() {
    if (this.rewardType == 'luckyspin') {
      let token = localStorage.getItem(SESSION.TOKEN)
      this.rewardsUrl = format('{0}?token={1}&lang={2}', this.spinUrl, token, this.currentLanguage)
    } else if (this.rewardType == 'agerewards') {
      this.getUrlForRewards()
    } else {
      this.rewardsUrl = ''
    }
    this.getAngpaoTotalChances()
  },
  methods: {
    promptAngpaoPrize(angpao) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.AngpaoPrize`)
      dialog.dialogXButton = null
      dialog.message.push(
        angpao.success ? locale.getMessage(`message.Congratz`, [angpao.prize_value]) : locale.getMessage(`message.sorryPleaseTryAgain`, [errorCodeHelper.getErrorCodeDesc(angpao.code)])
      )

      dialog.button.push({
        title: locale.getMessage(`label.ok`),
        class: 'py-3',
        action: () => this.closePrizeAngpao()
      })

      this.openPageDialog(dialog)
    },
    unlockAngpao() {
      if (!this.isLoggedIn) {
        this.$parent.goToLoginPage()
      } else {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.UnlockOngOngAngPao`)
        dialog.dialogXButton = null
        dialog.message.push(locale.getMessage(`message.888MegaAngpao`))

        dialog.button.push({
          title: locale.getMessage(`label.notNow`),
          class: 'py-3',
          action: () => this.closePage()
        })

        dialog.button.push({
          title: locale.getMessage(`label.open`),
          class: 'py-3 primary',
          action: () => this.openAngpao()
        })

        this.openPageDialog(dialog)
      }
    },
    closePrizeAngpao() {
      this.$store.dispatch(`${RESET_ANGPAO_CLAIM}`)
      this.isClaimed = false
      this.getAngpaoTotalChances()
      this.closePageDialog()
    },
    closePage() {
      this.closePageDialog()
    },
    openAngpao() {
      this.$store.dispatch(`${ANGPAO_CLAIM}`)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    getAngpaoTotalChances() {
      this.$store.dispatch(`${ANGPAO_CHANCE_GET}`)
    },
    getUrlForRewards() {
      this.$store.dispatch(`${REWARDS_URL}`)
    }
  },
  watch: {
    angpao_claim() {
      if (this.$store.state.cms.angpaoClaim.complete) {
        this.isClaimed = true
        this.closePage()
        let angPaoResult = this.$store.state.cms.angpaoClaim
        setTimeout(
          function() {
            this.promptAngpaoPrize(angPaoResult)
          }.bind(this),
          2000
        )
      }
    },
    isLoggedIn() {
      if (this.rewardType == 'agerewards') {
        if (this.$store.state.member.isLoggedIn) {
          this.getUrlForRewards()
        }
      }
      return this.$store.state.member.isLoggedIn
    },
    ageUrl() {
      if (this.$store.state.member.rewards.complete) {
        if (this.$store.state.member.rewards.success) {
          try {
            this.$ga.event('Rewards', `${!this.isLoggedIn ? 'Browse' : 'Login'} Rewards`, `AgeRewards`)
          } catch (err) {
            console.log(err)
          }
          window.open(this.$store.state.member.rewards.rewardsUrl, '_blank')
          this.$store.dispatch(`${RESET_REWARDS_URL}`)
          this.$router.push({
            name: ROUTE_NAME.REWARDS_BY_TYPE,
            params: {
              type: 'luckyspin'
            }
          })
        }
      }
    },

    rewardsType() {
      if (this.$route.params.type == 'luckyspin') {
        let token = localStorage.getItem(SESSION.TOKEN)
        this.rewardsUrl = format('{0}?token={1}&lang={2}', this.spinUrl, token, this.currentLanguage)
      } else if (this.rewardType == 'agerewards') {
        if (!this.isLoggedIn) {
          let obj = {
            isTransfer: false
          }
          this.$eventHub.$emit('open-login-dialog', obj)
        } else {
          this.getUrlForRewards()
        }
      } else {
        this.rewardsUrl = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.padding-angpao-frame {
  padding-left: 55px;
  padding-right: 55px;
}

.daily_check_in,
.ageRewards {
  position: relative;
  z-index: 1;
  min-height: 500px;
}

html {
  overflow: auto !important;
  width: 100%;
  height: 100%;
}

.is_future {
  opacity: 0.3;
  cursor: not-allowed;
}

.padding-huge {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.rewards-frame-container {
  /*position: relative;*/
  /*padding-bottom: 56.25%; !* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) *!*/
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.rewardsFrame,
.ageFrame {
  width: 100%;
  height: 100vh;
  border: 0;
  overflow: hidden;
}

.header_daily {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.border-curve {
  min-height: 220px;
}

.active-header {
  background: linear-gradient(180deg, #eebe26 0%, #fa9e5a 100%);
}

.border-curve {
  border-radius: 10px;
}

.not-active-header {
  background-color: #5e65dd;
}

.not_future.border-curve.on-hover.theme--dark {
  .v-card__text {
    color: #ff8216;
  }

  cursor: pointer;
  zoom: 1.1;
  background-color: rgba(#fff, 0.8);
}

@media (max-width: 699px) {
  .daily_check_in,
  .ageRewards {
    min-height: 180px;
  }
}
</style>
